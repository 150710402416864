.App {
  font-family: 'Lucida Console', monospace;
}
div.thing {
  display: block;
  width: 200px;
  height: 100px;
}
.first {
  font-size: 24px;
  background-color: #9BBCE3;
  height: 100px;
}
.first::after {
  content: "";
  height: 100%;
  display: inline-block;
}